import React from 'react';
import styled from 'styled-components';
import useCopyClipboard from '@/hooks/useCopyClipboard';
import { LinkStyledButton } from '@/theme';
import { CheckCircle, Copy } from 'react-feather';
import { useTranslation } from 'react-i18next';

const CopyIcon = styled(LinkStyledButton)`
  color: ${({ theme }) => theme.text3};
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  font-size: 0.825rem;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => theme.text2};
  }
`;
const TransactionStatusText = styled.span`
  margin-left: 0.25rem;
  font-size: 0.825rem;
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  color: ${({ theme }) => theme.primary1};
`;

export default function CopyHelper(props: {
  toCopy: string;
  children?: React.ReactNode;
  size?: string;
}) {
  const [isCopied, setCopied] = useCopyClipboard();
  const { size = '16' } = props;

  return (
    <CopyIcon onClick={() => setCopied(props.toCopy)}>
      {isCopied ? (
        <TransactionStatusText>
          <CheckCircle size={size} />
          {/* <TransactionStatusText>{t('Copied')}</TransactionStatusText> */}
        </TransactionStatusText>
      ) : (
        <TransactionStatusText>
          <Copy size={size} />
        </TransactionStatusText>
      )}
      {isCopied ? '' : props.children}
    </CopyIcon>
  );
}
