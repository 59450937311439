import React, { useState } from 'react';
import Loader from '@/components/Loader';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'react-feather';

const Content = styled.div<{ showInfo: boolean }>`
  height: ${({ showInfo }) => (showInfo ? '0px' : '90px')};
  overflow: hidden;
  transition: all 0.3s;
  & li {
    margin-bottom: 4px;
  }
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface IndexType {
  decimals: number;
  useBillInfo: any;
}

const type: any = {
  0: 3,
  1: 6,
  2: 9,
};

const Index: React.FC<IndexType> = (props: IndexType) => {
  const { decimals, useBillInfo } = props;
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const isNumLoading = (num: any) => <>{decimals && num ? num : <Loader />}</>;
  const { t } = useTranslation();

  return (
    <div>
      <Title onClick={() => setShowInfo(!showInfo)}>
        {t('Details')}
        {showInfo ? <ChevronDown /> : <ChevronUp />}
      </Title>
      <Content className="bill-info" showInfo={showInfo}>
        <ul className="bill-info">
          <li>
            {t('Lock up period')}：{isNumLoading(type[useBillInfo?.billType])}
            {type[useBillInfo?.billType] && t('Months')}
          </li>
          <li>
            {t('Start')}：{isNumLoading(useBillInfo?.lockStartTime)}
          </li>
          <li>
            {t('End')}：{isNumLoading(useBillInfo?.lockEndTime)}
          </li>
        </ul>
      </Content>
    </div>
  );
};
export default Index;
