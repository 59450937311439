import React from 'react';
// import { PRECISION } from '@/constants';
import styled from 'styled-components';
import Loader from '@/components/Loader';
import QuestionHelper from '@/components/QuestionHelper';

const Tooltip = styled(QuestionHelper)`
  color: ${({ theme }) => theme.primary1};
`;
const StyledBalanceText = styled.span<{ isNaN: boolean }>`
  white-space: nowrap;
  padding: ${({ isNaN }) => (isNaN ? '0.2rem' : '')};
  /* vertical-align: middle; */
`;

function Balance(props: { balance: string | number; icon?: any }) {
  const { balance, icon = <></> } = props;
  const num =
    balance?.toString().match(/^\d+(?:\.\d{0,2})?/) || Number(balance);
  return (
    <StyledBalanceText
      {...props}
      isNaN={!(Number(balance) > Number(num))}
      className="balance"
    >
      {icon}
      {/* {balance ?? '' !== '' ? ( */}
      {Number(balance) >= 0 ? (
        <>
          {Number(balance) > Number(num) ? (
            <Tooltip text={balance?.toString()} icon={num + '...'} />
          ) : (
            num
          )}
        </>
      ) : (
        <Loader />
      )}
    </StyledBalanceText>
  );
}

export default Balance;
