import { createReducer, createSlice } from '@reduxjs/toolkit'
import { ApifoxModel, Data } from './data.d'
import { invitationAdd, INVITATION_ADD } from './actions'

const initialState = {
  address: '',
  address_id: 0,
  created_at: '',
  invitation_code: '',
  invitation_count: 0,
  invitation_sum_revenue: '',
  invitation_yesterday_revenue: '',
  level: 0,
  my_team_level: 0,
  parent_address: '',
  parent_level: 0,
  team_address: '',
  team_level: 0,
  team_sum_revenue: '',
  team_yesterday_revenue: '',
  tvl: 0
}

export default function user(state = initialState, action: any) {
  switch (action.type) {
    case INVITATION_ADD:
      return {
        ...state,
        ...action.value
      }
    default:
      return state
  }
}
