import { createAction, createReducer } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
export const setFarmsHash = createAction<{ hash: string }>('farms/hash');

export function useSetFarmsHash(): (key: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (hash: string) => {
      dispatch(setFarmsHash({ hash }));
    },
    [dispatch],
  );
}

export interface SwapState {
  readonly hashList: any[];
}

const initialState: SwapState = {
  hashList: [],
};

export default createReducer<any>(initialState, builder =>
  builder.addCase(setFarmsHash, (state, { payload }) => {
    return {
      hashList: [payload, ...state.hashList],
    };
  }),
);
