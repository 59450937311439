import React from 'react';
import styled from 'styled-components';
import { ButtonPrimary } from '@/components/Button';
import { useActiveWeb3React } from '@/hooks';
import { useWalletModalToggle } from '@/state/application/hooks';
import Loader from '@/components/Loader';
import { useOpenFarms } from '@/newPages/farms/hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Btn = styled(ButtonPrimary)`
  border-radius: 18px;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  white-space: nowrap;
  height: ${({ height }) => (height ? height : 'auto')};
  z-index: 0;
`;

export const ButtonPrimaryView = styled(ButtonPrimary)<{ height: string }>`
  height: ${({ height }) => (height ? height : 'auto')};
  margin: auto;
`;

interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
  data: any;
  isShowOpen?: boolean;
  height?: string;
}

const Index = (props: IndexType) => {
  const { data, isShowOpen = true, height = '' } = props;
  const { account } = useActiveWeb3React();
  const toggleWalletModal = useWalletModalToggle(); // toggle wallet when disconnected
  const res = useOpenFarms(data);
  const { t } = useTranslation();
  const { push } = useHistory();
  if (!account) {
    return (
      <ButtonPrimaryView onClick={toggleWalletModal} height={height}>
        {t('Connect Wallet')}
      </ButtonPrimaryView>
    );
  }
  if (!data || !res) {
    return (
      <div className="flex-center">
        <Loader size="40px" />
      </div>
    );
  }
  if (data && res && !res?.isOpen && isShowOpen) {
    return (
      <ButtonPrimaryView
        height={height}
        onClick={res?.openFarms}
        disabled={res?.loading}
      >
        {t('enable')}
      </ButtonPrimaryView>
    );
  }

  return (
    <Btn
      height={height}
      onClick={() => push(`/farms/pledge/stake/${data?.pid}`)}
    >
      {t('stake')}
    </Btn>
  );
};
export default Index;
