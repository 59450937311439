import React, { useEffect, useState } from 'react';
import Loader from '@/components/Loader';
import Balance from '../Balance';
import { useBalance } from '@/newPages/hooks';
import BigNumber from 'bignumber.js';

const TokenNum = (props: {
  address: any;
  num?: string | number;
  icon?: any;
}) => {
  const { address, num = null, icon } = props;
  const { Balance: balance, decimals, token } = useBalance(address) || {};
  const [transition, setTransition] = useState<boolean>(false);

  useEffect(() => {
    setTransition(true);
    const time: any = setTimeout(() => setTransition(false), 150);
    return () => {
      clearTimeout(time);
    };
  }, [address]);

  if (!decimals || address !== token || transition) {
    return <Loader />;
  }

  return (
    <Balance
      balance={
        num
          ? BigNumber(Number(num))
              .div(Math.pow(10, decimals))
              .toFixed()
          : balance || ''
      }
      icon={icon}
    />
  );
};
export default TokenNum;
