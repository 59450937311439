import React, { useState } from 'react';
import { useGetPledgeType } from '../hooks';
import BalanceView from '@/newPages/farms/component/Balance';
import Loader from '@/components/Loader';
import styled from 'styled-components';
import { Input as NumericalInput } from '@/components/NumericalInput';
import { ButtonPrimary } from '@/components/Button';
import { useFarmsApi } from '@/newPages/farms/hooks';
import { farmsType } from '@/newPages/1230';
import { useTranslation } from 'react-i18next';
import { useGetLp } from '@/newPages/farms/hooks';
import { useActiveWeb3React } from '@/hooks';
import { StyledBalanceMax } from '@/components/CurrencyInputPanel';
import FarmsBtn from '../../list/component/farmsBtn';

export const NumericalInputBox = styled(NumericalInput)`
  padding: 0;
  margin-right: 8px;
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 10px;
  padding: 12px 16px;
  width: 100%;
  font-size: 20px;
  color: ${({ theme }) => theme.primary1};
`;

const InputWrap = styled.div`
  position: relative;
`;

const StyledBalanceMaxBtn = styled(StyledBalanceMax)`
  color: ${({ theme }) => theme.primary1};
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
  margin: 0;
`;

const PledgeView = styled.div`
  & .title {
    font-size: 14px;
    font-weight: bold;
  }
  .radio_wrap {
    margin: 22px 0 24px;
  }
`;

const HeaderBalance = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  & .title {
    font-size: 20px;
    font-weight: bold;
  }
  & .symbol {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
`;
const Radio = styled.div<{ active: boolean }>`
  width: 85px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background: #ffffff;
  border-radius: 14px;
  font-size: 16px;
  background: ${({ theme }) => theme.secondary3};
  ${({ active, theme }) => {
    if (active) {
      return {
        background: theme.primary1,
        color: theme.bg1,
      };
    }
    return {};
  }};
`;

interface IndexType {
  lp: farmsType;
}

const Pledge: React.FC<IndexType> = (props: IndexType) => {
  const { lp } = props;
  const { pledge, loading } = useFarmsApi(lp) ?? {};
  const { isOpen, decimals, Balance } = useGetLp(lp) || {};
  const pledgeType: any = useGetPledgeType();
  const { account } = useActiveWeb3React();

  const [depositValue, setDepositValue] = useState<any>('0'); // 存款金额
  const [depositType, setDepositType] = useState<number>(0); // 存款类型
  const { t } = useTranslation();

  const clickPledge = () => {
    pledge?.(depositValue, depositType);
  };

  const isNumLoading = (num: any) => (
    <>{decimals && num ? <BalanceView balance={num} /> : <Loader />}</>
  );

  return (
    <PledgeView>
      <HeaderBalance>
        <div className="title">{lp?.lpSymbol}</div>
        <span>
          {t('balance')}
          {isNumLoading(Balance)}
        </span>
      </HeaderBalance>
      <InputWrap>
        <NumericalInputBox
          value={depositValue}
          onUserInput={setDepositValue}
          disabled={loading}
        />
        <>
          {account && Number(depositValue) !== Number(Balance) && (
            <StyledBalanceMaxBtn onClick={() => setDepositValue(Balance)}>
              MAX
            </StyledBalanceMaxBtn>
          )}
        </>
      </InputWrap>

      <div className="radio_wrap">
        {pledgeType ? (
          <div className="flex-between">
            {pledgeType?.map((item: any) => {
              return (
                <Radio
                  key={item.value}
                  active={item.value === depositType}
                  onClick={() => {
                    if (loading) return;
                    setDepositType(item.value);
                  }}
                  className="cursor"
                >
                  {item.label}
                </Radio>
              );
            })}
          </div>
        ) : (
          <div className="flex-center">
            <Loader size="35px" />
          </div>
        )}
      </div>
      {isOpen ? (
        <ButtonPrimary
          className="button"
          onClick={clickPledge}
          disabled={loading || !isOpen || depositValue <= 0}
        >
          {t('stake')}
        </ButtonPrimary>
      ) : (
        <FarmsBtn data={lp} />
      )}
    </PledgeView>
  );
};
export default Pledge;
