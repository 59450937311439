import React, { useState } from 'react';
import styled from 'styled-components';
import { LinkStyledButton } from '@/theme/components';
import { ChevronDown } from 'react-feather';
const SelectView = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 8px;
`;

const Menu = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  & > .menuList {
    margin: 30px;
    background: ${({ theme }) => theme.bg1};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.bg3};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 10px;
    color: ${({ theme }) => theme.text1};
  }
`;

const LinkStyledButtonView = styled(LinkStyledButton)<{ active: boolean }>`
  white-space: nowrap;
  font-weight: ${({ active }) => (active ? 'bold' : '')};
  min-height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
`;

const SelectLabel = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  & > span {
    margin-right: 5px;
  }
`;
const ClickView = styled.div`
  display: flex;
  align-items: center;
`;

const ItemIcon = styled.img`
  width: 30px;
  height: 30px;
  margin: 5px 10px 5px 0;
`;

type itemType = { value: string | number; label: string; icon?: string };
interface SelectType {
  isVisible?: boolean;
  onClick?: (type: number | string) => any;
  options?: itemType[];
  defaultValue?: string | number;
  children?: any;
  iconView?: any;
}
const Select: React.FC<SelectType> = (props: SelectType) => {
  const {
    options = [],
    defaultValue = '',
    onClick,
    children,
    iconView = null,
  } = props;
  const [isShow, setInShow] = useState<boolean>(false);
  const [key, setKey] = useState<string | number>(
    defaultValue || (options[0] && options[0].value),
  );

  const change = (type: string | number) => {
    onClick?.(type);
    setKey(type);
    setInShow(false);
  };

  const isShowIcon = (icon: string | undefined) => (
    <>{icon && <ItemIcon src={icon} className="select_icon" />}</>
  );

  const findItem = () => options.find((item: itemType) => item.value === key);
  return (
    <>
      {options?.length > 0 && (
        <SelectView
          className="select"
          onMouseOver={() => {
            setInShow(true);
          }}
          onMouseLeave={() => {
            setInShow(false);
          }}
        >
          <ClickView
            onClick={() => {
              setInShow(!isShow);
            }}
          >
            {!iconView ? (
              <SelectLabel style={{ whiteSpace: 'nowrap' }}>
                {isShowIcon(findItem()?.icon)}
                <span>{options.length > 0 && findItem()?.label}</span>
                <ChevronDown style={{ fontSize: '12px' }} />
              </SelectLabel>
            ) : (
              iconView
            )}
          </ClickView>

          <Menu isShow={isShow}>
            <div
              onMouseLeave={() => {
                setInShow(false);
              }}
              className="menuList"
            >
              {!children &&
                options.map(item => {
                  return (
                    <LinkStyledButtonView
                      onClick={() => change(item.value)}
                      key={item.value}
                      active={key === item.value}
                    >
                      {isShowIcon(item?.icon)}
                      {item.label}
                    </LinkStyledButtonView>
                  );
                })}
              {children && children}
            </div>
          </Menu>
        </SelectView>
      )}
    </>
  );
};
export default Select;
