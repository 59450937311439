import React, { useContext } from 'react';
import { AlertCircle } from 'react-feather';
import styled, { ThemeContext } from 'styled-components';
import { useActiveWeb3React } from '@/hooks';
import { TYPE } from '@/theme';
import { ExternalLink } from '@/theme/components';
import { getEtherscanLink } from '@/utils';
import { AutoColumn } from '../Column';
import { AutoRow } from '../Row';
import success_img from '@/assets/images/icon/success.png';

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`;

const Row = styled(ExternalLink)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string;
  success?: boolean;
  summary?: string;
}) {
  const { chainId } = useActiveWeb3React();

  const theme = useContext(ThemeContext);

  return (
    <RowNoFlex>
      <AutoColumn gap="8px">
        <TYPE.body fontWeight={500} className="summary flex-item-center">
          {success ? (
            // <CheckCircle color={theme.green1} size={24} />
            <img src={success_img} style={{ width: '20px' }} />
          ) : (
            <AlertCircle color={theme.red1} size={24} />
          )}
          <span>
            {summary ??
              'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}
          </span>
        </TYPE.body>
        {chainId && (
          <Row href={getEtherscanLink(chainId, hash, 'transaction')}>
            View on Fiboscan：{hash}
          </Row>
        )}
      </AutoColumn>
    </RowNoFlex>
  );
}
