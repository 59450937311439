import React, { useCallback } from 'react';
import styled from 'styled-components';

const StyledRangeInput = styled.input<{ size: number; value: number }>`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  /* &[type=range] {  
				cursor: pointer;
			  -webkit-appearance: none;/*清除系统默认样式
			  width: 200px;
			  height: 10px;  
			  background: #ddd;
			  background: -webkit-linear-gradient(top, rgb(97, 189, 18), rgb(97, 189, 18)) 0% 0% / 50% 100% no-repeat rgb(221, 221, 221);
			  height: 10px;/*横条的高度
			}  */

  /*拖动块的样式*/

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    background-color: ${({ theme }) => theme.primary1};
    border-radius: 100%;
    border: none;
    transform: translateY(-50%);
    /* color: ${({ theme }) => theme.primary1}; */

    &:hover,
    &:focus {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1),
        0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 24px 32px rgba(0, 0, 0, 0.04);
    }
  }

  &::-moz-range-thumb {
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    background-color: ${({ theme }) => theme.primary1};
    border-radius: 100%;
    border: none;
    /* color: ${({ theme }) => theme.bg1}; */

    &:hover,
    &:focus {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1),
        0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 24px 32px rgba(0, 0, 0, 0.04);
    }
  }

  &::-ms-thumb {
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    background-color: ${({ theme }) => theme.primary1};
    border-radius: 100%;
    /* color: ${({ theme }) => theme.bg1}; */

    &:hover,
    &:focus {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1),
        0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 24px 32px rgba(0, 0, 0, 0.04);
    }
  }

  &::-webkit-slider-runnable-track {
    /* background: linear-gradient(
      90deg,
      ${({ theme }) => theme.bg5},
      ${({ theme }) => theme.bg3}
    ); */
     background: ${({ theme, value }) => {
       return `-webkit-linear-gradient(top, ${theme.primary1}, ${theme.primary1}) 0% 0% / ${value}% 100% no-repeat rgb(221, 221, 221);`;
     }};
    height: 4px;
    border-radius: 4px;
  }

  &::-moz-range-track {
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.bg5},
      ${({ theme }) => theme.bg3}
    );
    height: 4px;
  }

  &::-ms-track {
    width: 100%;
    border-color: transparent;
    color: transparent;

    background: ${({ theme }) => theme.bg5};
    height: 4px;
  }
  &::-ms-fill-lower {
    background: ${({ theme }) => theme.bg5};
  }
  &::-ms-fill-upper {
    background: ${({ theme }) => theme.bg3};
  }

  &[disabled]{
    &::-webkit-slider-thumb {
      background-color: ${({ theme }) => theme.bg5};
    }
  }
`;

interface InputSliderProps {
  value: number;
  onChange: (value: number) => void;
  step?: number;
  min?: number;
  max?: number;
  size?: number;
  disabled?: boolean;
}

export default function Slider({
  value,
  onChange,
  min = 0,
  step = 1,
  max = 100,
  size = 20,
  disabled = false,
}: InputSliderProps) {
  const changeCallback = useCallback(
    e => {
      onChange(parseInt(e.target.value));
    },
    [onChange],
  );

  return (
    <StyledRangeInput
      size={size}
      type="range"
      value={value}
      style={{
        // width: '90%',
        // marginLeft: 15,
        // marginRight: 15,
        padding: '15px 0',
      }}
      onChange={changeCallback}
      aria-labelledby="input slider"
      step={step}
      min={min}
      max={max}
      disabled={disabled}
    />
  );
}
