import { useMemo } from 'react';
import { useActiveWeb3React } from '@/hooks';
import { farmsType } from '@/newPages/1230';
import { useSingleResult } from '@/newPages/hooks';
import BigNumber from 'bignumber.js';
import { useMsterChefContract } from '@/hooks/useContract';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useGetPledgeType = () => {
  const masterChef: any = useMsterChefContract();
  const { t } = useTranslation();
  const getAllBillType = useSingleResult(masterChef, 'getAllBillType');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setpledgeType = (data: any[]): any[] => {
    const typesArr = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const label =
        element.duration === '0'
          ? '活期'
          : `${BigNumber(element.duration.toString())
              .div(60 * 60 * 24 * 30)
              .toFixed(0)} ${t('Months')}`;
      typesArr.push({
        value: index,
        label: label,
      });
    }
    return typesArr;
  };

  return useMemo(() => {
    if (!getAllBillType) return null;
    return setpledgeType(getAllBillType);
  }, [getAllBillType, setpledgeType]);
};

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface usePoolRedeemType {
  pendingFiboFarm: string; //  用户余额
  pendingFlyFarm: string;
  flyAddress: string;
  fiboAddress: string;
  // useMCBalance: string;
  billInfo: any;
}
const usePoolRedeemData: usePoolRedeemType = {
  pendingFiboFarm: '',
  flyAddress: '',
  fiboAddress: '',
  pendingFlyFarm: '',
  // useMCBalance: '',
  billInfo: {},
};

// 用户赚取
export const useGetPoolRedeem = (
  lp: farmsType,
  withdrawBillId: string,
): usePoolRedeemType | undefined => {
  const { account, chainId } = useActiveWeb3React();
  const masterChef: any = useMsterChefContract();
  const fiboFarm = useSingleResult(masterChef, 'fiboFarm');
  const flyFarm = useSingleResult(masterChef, 'flyFarm');
  // 获取用户在指定池子下，指定账单的收益: pid + user_address + bid
  const userPendingResult = useSingleResult(masterChef, 'pending', [
    lp.pid,
    account,
    withdrawBillId,
  ]);

  // // 用户账户在MC中的余额
  // const useMCBalance = useSingleResult(masterChef, 'userAmount', [
  //   lp.pid,
  //   account,
  // ])?.toString();
  // 用户账单信息
  const billInfo = useSingleResult(masterChef, 'userInfo', [
    lp.pid,
    account,
    withdrawBillId,
  ]);

  const setTime = (time: any) => {
    const strTime = time.toString();
    return strTime === '0'
      ? '-'
      : dayjs(strTime * 1000).format('YYYY-MM-DD HH:mm');
  };

  return useMemo(() => {
    if (!chainId) return undefined;
    if (
      !masterChef ||
      !fiboFarm ||
      !flyFarm ||
      // !useMCBalance ||
      !userPendingResult ||
      !billInfo
    ) {
      return usePoolRedeemData;
    }
    return {
      pendingFlyFarm: userPendingResult.pendingFlyFarm?.toString(),
      pendingFiboFarm: userPendingResult.pendingFiboFarm?.toString(),
      fiboAddress: fiboFarm,
      flyAddress: flyFarm,
      // useMCBalance,
      billInfo: {
        amount: billInfo.amount.toString(),
        billId: billInfo.billId.toString(),
        billType: billInfo.billType.toString(),
        timestamp: setTime(billInfo.timestamp),
        lockStartTime: setTime(billInfo.lockStartTime),
        lockEndTime: setTime(billInfo.lockEndTime),
      },
    };
  }, [chainId, fiboFarm, flyFarm, masterChef, billInfo, userPendingResult]);
};
