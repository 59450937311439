import React from 'react';
import { ButtonPrimary } from '@/components/Button';
import { useLpMappingInfo } from './hooks';
import { useTranslation } from 'react-i18next';

interface IndexType {
  mappingData?: any;
  balance?: string;
  onClick?: () => any;
  Confirm?: () => any;
  disabled?: boolean;
  confirmBtnTitle?: string;
}

const Index: React.FC<IndexType> = (props: IndexType) => {
  const { t } = useTranslation();

  const {
    mappingData,
    balance,
    onClick,
    disabled = false,
    confirmBtnTitle = t('Confirm'),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    Confirm = () => {},
  } = props;
  const { allowance, open, loading: btnLoading, FUmappingTokens }: any =
    useLpMappingInfo(mappingData) || {};

  const isBtnText = () => {
    if (allowance === '0') {
      return t('authorized');
    } else if (!mappingData) {
      return t('Please select a token');
    } else if (!balance) {
      return t('Quantity');
    } else {
      return confirmBtnTitle;
    }
  };
  const click = () => {
    if (allowance === '0') {
      open();
    } else {
      if (onClick) {
        return onClick?.();
      }
      FUmappingTokens(balance, Confirm);
    }
  };
  const isDisabled = !mappingData || btnLoading;

  return (
    <>
      <ButtonPrimary
        onClick={click}
        disabled={
          disabled ||
          ((isDisabled || !balance) && allowance !== '0') ||
          allowance === undefined
        }
      >
        {isBtnText()}
      </ButtonPrimary>
    </>
  );
};
export default Index;
