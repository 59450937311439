import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { maxWidth } from '@/pages/AppBody';

const Header = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 33px;
  width: 100%;
  padding-bottom: 26px;
  margin-bottom: 20px;
  position: relative;
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: ${maxWidth};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #eee;
  }
  .token_symbol {
    margin-top: 20px;
    font-size: 20px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ArrowLeftIcon = styled(ArrowLeft)`
  cursor: pointer;
`;
interface IndexType {
  title: string;
  symbol?: string;
}

const Index: React.FC<IndexType> = (props: IndexType) => {
  const { title, symbol } = props;
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const click = () => {
    goBack();
  };
  return (
    <Header>
      <Title>
        <ArrowLeftIcon size="32px" onClick={click} />
        {title}
        {symbol && (
          <>
            {symbol} {t('farms pool')}
          </>
        )}
        <i />
      </Title>
    </Header>
  );
};
export default Index;
